import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { CanLeaveRoute } from 'src/app/core/guards/confirmation-guard.guard';
import { CartModel } from 'src/app/shared/models/mazaya-plus/cart-model';
import { MazayaPlusService } from '../../services';
import { MazayaEcardDetails } from 'src/app/shared/models/users/e-cart-details';
import * as CryptoJS from 'crypto-js';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/state';
import { MazayaEligibility } from 'src/app/shared/models/mazaya-plus/userInfo';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { MatRadioChange } from '@angular/material/radio';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Cart, MembershipCategory } from 'src/app/shared/models/mazaya-power/mazaya-power';
import { forEach } from 'lodash';
import { MazayaEcardDetail, MazayaeEcardMain, TabbyPaymentRequest } from 'src/app/shared/models/mazaya-plus/tabby-model';
import { MyMazayaPlusService } from 'src/app/features/my-mazaya-plus/services';
import { MazayaEcardData } from 'src/app/shared/models/mazaya-plus/membership-details-model';
import { MatSelectChange } from '@angular/material/select';

interface MazayaEcardMain {
  amount: number;
  currency: string;
  category_id: number;
  subcategoryIds: string;
  vat: number;
}

interface Request {
  additional_count: number;
  mazayaecarddetails: any[];
  mazayaecardmain: MazayaEcardMain;
}

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent {
  @ViewChild('formContainer') formContainer: ElementRef;
  // @ViewChild('applePayButton') applePayButton: ElementRef | undefined;
  maxDate: Date = this.calculateMaxDate();
  cartItemData: CartModel;
  categoryList: MembershipCategory[] = [];
  cartPower: Cart = {
    items: [], totalAmount: 0, totalVat: 0, grandTotal: 0,
    PowertypeId: 0,
    ExploretypeId: 0,
    powerType: '',
    exploreType: ''
  };
  showError: boolean = false;
  totalAmount: number = 0;
  totalVat: number = 0;
  grandTotalAmount: number = 0;
  myForm: FormGroup;
  isInputFocused = false;
  userFirstName = ""
  userLastName = ""
  isDisabled: boolean = true;
  formContent = ``;
  subcategoryIds: string = ""
  token = "";
  today: Date = new Date();
  isChildOnly: boolean = false;
  isNannyOnly: boolean = false;
  relations = [];
  submitted = false;
  userInfo: MazayaEligibility;
  isCreatedCard: boolean = false;
  userEmail: string = "";
  userGender: string = ""
  subcategoryId: number;
  isV2: boolean = false;
  cartType: string = ""
  ipower: boolean = false;
  cartItemV2Data = []
  phoneNumber: string = "";
  gender: string = ""
  mobileNumberError: boolean = false
  isUpgrade: boolean = false
  upgradeAmountTotal: number = 0
  upgradeVatTotal: number = 0
  upgradeGrandTotal: number = 0
  upgradTo: number = 0;
  currency: string = '';
  upgradSubIds: string = "";
  selectedPaymentMethod: string = '';
  isOldToNew: boolean = true
  membershipDetails: any
  membersEmpty: boolean = true
  currentMember: number = 1;
  filteredMembers: any
  isAdult: boolean | null = null;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _mazayaPlusService: MazayaPlusService,
    private _myMazayaPlusService: MyMazayaPlusService,
    private _storageService: StorageService,
    private store: Store,
    private http: HttpClient,
    private _notification: NotificationsService,
    private zone: NgZone,
    private _route: ActivatedRoute,
  ) {
    this.myForm = this.fb.group({
      items: this.fb.array([]),
      agreeConditions: [false, Validators.requiredTrue]
    });
  }

  // calculateCartPowerTotals() {
  //   if (this.cartPower && Array.isArray(this.cartPower)) {
  //       const total = this.cartPower.reduce((acc, item) => {

  //       }, 0);
  //   } else {
  //       console.error('cart is not defined or not an array');
  //   }
  // }

  ngOnInit() {
    // this.fetchPosts();
    this.initUserInfo();


  }
  selectPaymentMethod(method: string) {
    this.selectedPaymentMethod = method;
    this.showError = false;
  }
  confirmSelection(): void {
    if (!this.selectedPaymentMethod) {
      this.showError = true; // Show error if no method is selected
    } else {
      // Proceed with confirmation logic
      console.log('Payment method selected:', this.selectedPaymentMethod);
      this.showError = false;
    }
  }
  createFormPower() {
    this._mazayaPlusService.getUserProfile().subscribe(res => {
      this.userFirstName = res.firstName;
      this.userLastName = res.lastName;
      this.userEmail = res.email;

      this.phoneNumber = res.phoneNumber.substring(3);
      this.gender = res.gender;
      this.totalAmount = this.cartPower.totalAmount;
      this.totalVat = this.cartPower.totalVat;
      // this.subcategoryId=this.cartPower.items[0].cardId;

      this.itemControls.push(
        this.fb.group({
          firstName: [this.userFirstName, Validators.required],
          lastName: [this.userLastName, Validators.required],
          email: [this.userEmail, [Validators.required, Validators.email]],
          dob: ['', Validators.required],
          sub_type: ["power"],
          relation: 'Myself',
          phoneNumber: this.phoneNumber,
          gender: this.gender,
          totalAmount: this.totalAmount,
          totalVat: this.totalVat
        })
      )



    })

    // ;
  }
  createFormV2() {

    this.amountCalculationV2();
    let mainCount = 0;
    let adultCount = 0;
    let childCount = 0;
    let singleCount = 0;
    let nannyCount = 0;


    for (let i = 0; i < this.cartItemV2Data[0].membershipTypes.length; i++) {

      if (this.cartItemV2Data[0].membershipTypes[i].cardName === "FAMILY MEMBERSHIP") {
        mainCount = this.cartItemV2Data[0].membershipTypes[i].count;
      } else if (this.cartItemV2Data[0].membershipTypes[i].cardName === "SINGLE MEMBERSHIP") {
        singleCount = singleCount + this.cartItemV2Data[0].membershipTypes[i].selectedCount;
      } else if (this.cartItemV2Data[0].membershipTypes[i].cardName === "ADDITIONAL ADULT MEMBERSHIP") {
        adultCount = adultCount + this.cartItemV2Data[0].membershipTypes[i].selectedCount;
      }
      else if (this.cartItemV2Data[0].membershipTypes[i].cardName === "ADDITIONAL CHILD MEMBERSHIP") {
        childCount = childCount + this.cartItemV2Data[0].membershipTypes[i].selectedCount;
      }
      else if (this.cartItemV2Data[0].membershipTypes[i].cardName === "NANNY SERVICE") {
        nannyCount = nannyCount + this.cartItemV2Data[0].membershipTypes[i].selectedCount;
      }
    }
    for (let i = 0; i < mainCount; i++) {
      if (i >= 2) {
        this.itemControls.push(this.createItemFormGroup1("FAMILY", 'Child'));
      } else {
        this.itemControls.push(this.createItemFormGroup1("FAMILY", 'Adult'));
      }

      if (this.myForm.value.items.length > 0 && i == 0) {
        this.getUserProfile();
      }

    }
    for (let i = 0; i < singleCount; i++) {
      this.itemControls.push(this.createItemFormGroup1("SINGLE", 'Adult'));
      if (this.myForm.value.items.length > 0 && i == 0) {
        this.getUserProfile();
      }
    }
    for (let i = 0; i < adultCount; i++) {
      this.itemControls.push(this.createItemFormGroup1("ADDITIONAL ADULT", 'Adult'));

    }
    for (let i = 0; i < childCount; i++) {
      this.itemControls.push(this.createItemFormGroup1("ADDITIONAL CHILD", 'Child'));
    }
    for (let i = 0; i < nannyCount; i++) {
      this.itemControls.push(this.createItemFormGroup1("NANNY SERVICE", 'Nanny'));
    }
    let famArray = this.cartItemV2Data[0].membershipTypes.find(item => item.cardName === "FAMILY MEMBERSHIP");
    let singleArray = this.cartItemV2Data[0].membershipTypes.find(item => item.cardName === "SINGLE MEMBERSHIP");
    let nannyArray = this.cartItemV2Data[0].membershipTypes.find(item => item.cardName === "NANNY SERVICE");
    if (famArray === undefined || singleArray === undefined) {
      if (this.cartItemV2Data[0].membershipTypes[0].cardName === "ADDITIONAL CHILD MEMBERSHIP") {
        this.isChildOnly = true;
      }


    }


    // for (let i = 1; i < totalCount; i++) {
    //   this.addItem();
    // }
    for (let i = 0; i < this.cartItemV2Data[0].membershipTypes.length; i++) {
      if (i != 0)
        this.subcategoryIds = this.subcategoryIds + "," + this.cartItemV2Data[0].membershipTypes[i].cardId.toString()
      else
        this.subcategoryIds = this.cartItemV2Data[0].membershipTypes[i].cardId.toString()
    }

    if (this.isOldToNew) {
      this.relations = [
        { value: 'Myself', viewValue: 'Myself' },
        { value: 'Father', viewValue: 'Father' },
        { value: 'Mother', viewValue: 'Mother' },
        { value: 'Son', viewValue: 'Son' },
        { value: 'Daughter', viewValue: 'Daughter' },
        { value: 'Spouse', viewValue: 'Spouse' },
        { value: 'Other', viewValue: 'Other' },
      ]
      this._myMazayaPlusService.getEcardsRenew()
        .subscribe((res) => {
          this.membershipDetails = res.data
          if ((this.membershipDetails.length > 0 && this.userInfo?.mazayaCardCount === 0) || (this.membershipDetails.length > 0 && this.userInfo?.mazayauserdetails.isPurchasedMazayaPlusOld)) {
            this.myForm.get('items.0').setValue({
              firstName: this.membershipDetails[0].firstname,
              lastName: this.membershipDetails[0].lastname,
              email: this.membershipDetails[0].email,
              dob: this.membershipDetails[0].dob,
              relation: this.membershipDetails[0].relation,
              gender: this.membershipDetails[0].gender,
              phoneNumber: this.membershipDetails[0].phoneNumber.substring(3),
              sub_type: this.membershipDetails[0].sub_type,
              selection: 'new',
              profile_image: this.membershipDetails[0].profile_image,
              memberType: 'Adult'
            });
          }

        })

    }

  }
  loadCart(): void {
    const storedCart = localStorage.getItem('cartPower');
    this.cartPower = storedCart
      ? JSON.parse(storedCart)
      : { items: [], totalAmount: 0, totalVat: 0, grandTotal: 0 };
    this.createFormPower()
  }
  deleteCartItem(index: number): void {
    this.cartPower.items.splice(index, 1);
    this.cartPower.totalAmount = this.cartPower.items.reduce((sum, item) => sum + item.amount * item.count, 0);
    this.cartPower.totalVat = this.cartPower.items.reduce((sum, item) => sum + item.vat * item.count, 0);
    this.cartPower.grandTotal = this.cartPower.totalAmount + this.cartPower.totalVat;

    localStorage.setItem('cartPower', JSON.stringify(this.cartPower));
  }
  trackByFn(index: number, item: any): any {
    return item.id; // Replace with a unique identifier for each item in your case
  }
  get itemControls() {
    return this.myForm.get('items') as FormArray;
  }

  createItemFormGroup() {
    return this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      dob: ['', Validators.required],
      relation: ['', Validators.required],
      gender: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(9)]],
      sub_type: [""],

    });
  }

  createItemFormGroup1(subtype: string, memberType: string) {
    return this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      dob: ['', Validators.required],
      relation: ['', Validators.required],
      gender: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(9)]],
      sub_type: [subtype],
      selection: 'new',
      profile_image: '',
      memberType: memberType
    });

  }
  addItem() {
    this.itemControls.push(this.createItemFormGroup());
  }

  removeItem(index: number) {

    this.itemControls.removeAt(index);

  }

  amountCalculation() {
    this.totalVat = 0;
    this.totalAmount = 0;

    for (let i = 0; i < this.cartItemData.subCategory.length; i++) {
      this.totalVat = this.totalVat + (this.cartItemData.subCategory[i].vat) * this.cartItemData.subCategory[i].count
      this.totalAmount = this.totalAmount + (this.cartItemData.subCategory[i].amount) * this.cartItemData.subCategory[i].count

    }
    this.grandTotalAmount = this.totalAmount


  }
  onIncrement(i: number) {
    if (this.cartItemData.subCategory[i].count != this.cartItemData.subCategory[i].totalcount) {
      this.cartItemData.subCategory[i].count++
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      this.amountCalculation()

      if (this.cartItemData.subCategory[i].sub_type === "ADDITIONAL ADULT") {
        this.itemControls.push(this.createItemFormGroup1("ADDITIONAL ADULT", 'Adult'));

      }
      if (this.cartItemData.subCategory[i].sub_type === "ADDITIONAL CHILD") {
        this.itemControls.push(this.createItemFormGroup1("ADDITIONAL CHILD", 'Child'));
      }
    } else {
      this._notification.error(
        "",
        "Maximum count reached"
      );
    }

  }
  onDecrement(i: number) {


    if (this.cartItemData.subCategory[i].count !== 0) {
      this.cartItemData.subCategory[i].count--
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      if (this.cartItemData.subCategory.length > 1 && this.userInfo?.mazayaCardCount != 0) {
        this.removeItem(0)
      } else {
        this.removeItem(this.itemControls.controls.length - 1)
      }

      if (this.cartItemData.subCategory[i].count == 0)
        this.deleteSubCategory(i);
      this.amountCalculation();



    }
  }
  onInputFocus() {
    this.isInputFocused = true;
  }

  onInputBlur() {
    this.isInputFocused = false;
  }

  async confirm(nextState?: RouterStateSnapshot) {

  }
  getUserProfile() {
    this._mazayaPlusService.getUserProfile().subscribe(res => {

      this.userFirstName = res.firstName;
      this.userLastName = res.lastName;
      this.userEmail = res.email;
      this.phoneNumber = res.phoneNumber.substring(3);
      this.gender = res.gender;

      if (this.userInfo?.mazayaCardCount === 0) {

        this.isCreatedCard = false
        console.log("userFirstName2", this.myForm.value.items.length, this.userFirstName)
        this.myForm.value.items.length
        this.myForm.get('items.0').setValue({
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          dob: '',
          relation: 'Myself',
          sub_type: this.myForm.get('items.0.sub_type').value,
          phoneNumber: this.phoneNumber,
          gender: this.gender,
          selection: 'new',
          profile_image: '',
          memberType: 'Adult'
        });
      } else {
        this.isCreatedCard = true;
      }
    })

  }

  onSubmitPower() {
    this.submitted = true;
    if (!this.selectedPaymentMethod) {
      this.showError = true;
      this._notification.error("", "Please select a payment method before proceeding.");
      return;
    } else {
      this.showError = false;
    }
    if (!this.myForm.valid) {
      return;
    }
    const itemControl = this.myForm.get('items.0');

    if (!itemControl) {
      this._notification.error("", "No members found.");
      return;
    }
    const formValue = itemControl.value;
    const mazayaecarddetails = this.cartPower.items.map((item) => ({
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      dob: formValue.dob,
      relation: formValue.relation,
      gender: formValue.gender,
      sub_type: item.cardName,
      type: item.type?.toUpperCase(),
      phoneNumber: '971' + formValue.phoneNumber,
      subcategoryId: Number(item.cardId),
    }));

    const currency = this.cartPower.items.length > 0 ? this.cartPower.items[0].currency : '';

    const exploreTypeId = this.cartPower.ExploretypeId ? `${this.cartPower.ExploretypeId}` : '';
    const powerTypeId = this.cartPower.PowertypeId ? `${this.cartPower.PowertypeId}` : '';

    const categoryIds = [exploreTypeId, powerTypeId].filter((id) => id).join(',');

    const subcategoryIds = this.cartPower.items.map((item) => item.cardId).join(',');

    let mazayaType = 0;
    if (this.cartPower.powerType === "Mazaya Power" && this.cartPower.exploreType === "Mazaya Explore") {
      mazayaType = 4;
    } else if (this.cartPower.powerType === "Mazaya Power") {
      mazayaType = 3;
    } else if (this.cartPower.exploreType === "Mazaya Explore") {
      mazayaType = 2;
    }

    const mazayaecardmain = {
      amount: formValue.totalAmount,
      currency: currency,
      category_ids: categoryIds,
      subcategoryIds: subcategoryIds,
      vat: formValue.totalVat,
      mazayaType: mazayaType,
    };

    const requestBody = {
      mazayaecarddetails,
      mazayaecardmain,
    };

    console.log("Request Body:", requestBody);


    this._mazayaPlusService.createPower(requestBody).subscribe({
      next: (response) => {
        // this._notification.success("", "Power card created successfully!");
        console.log("Response:", response);
        if (response.data != 0) {
          const lastTransaction: { type: string, transactionId: string } = {
            type: this.cartType,
            transactionId: response.data
          }


          localStorage.setItem('lastTransaction', JSON.stringify(lastTransaction))
          if (this.selectedPaymentMethod === 'Credit/Debit Card') {

            this.handlePowerPayment(response.data, requestBody.mazayaecarddetails[0].email);
          } else if (this.selectedPaymentMethod === 'Tabby') {

            this.createPowerPaymentWithTabby(response.data);
          }
        } else {
          this._notification.error("Failed", "Please enter a valid Email");
        }
      },
      error: (error) => {
        this._notification.error("", "Failed to create Power card");
        console.error("Error:", error);
      },
    });
  }
  // fetchPosts(): void {
  //   debugger
  //   const apiUrl = 'https://atlas-utility.gewaninfotech.com/utility/1.0/tabby/mazaya-payment/createPayment';

  //   const requestBody = {
  //     additional_count: 0,
  //     mazayaecarddetails: [
  //       {
  //         email: 'tom@gamil.com',
  //         dob: '2024-11-11',
  //         relation: 'Son',
  //         lastname: 'Tommy',
  //         firstname: 'tom',
  //         sub_type: 'ADDITIONAL CHILD',
  //         mazayaEcardmainId: 0,
  //         gender: 'Male',
  //       },
  //     ],
  //     mazayaecardmain: {
  //       category_id: 27,
  //       vat: '60.0',
  //       amount: '120000.0',
  //       currency: 'AED',
  //       id: 0,
  //       subcategoryIds: '44',
  //     },
  //     name: 'Family Membership',
  //     booking_id: 3299,
  //     description: '',
  //   };

  //   this.http.post(apiUrl, requestBody).subscribe(
  //     (response) => {
  //       debugger
  //       console.log('Response received:', response);
  //     },
  //     (error) => {
  //       debugger
  //       console.error('Error occurred:', error);
  //     }
  //   );
  // }
  createPowerPaymentWithTabby(transaction_id: number) {

    console.log("createPaymentWithTabby called with transaction_id:", transaction_id);
    console.log("ghhhhhhhhhhhhhhh", this.myForm.value.items);

    const currency = this.cartPower.items.length > 0 ? this.cartPower.items[0].currency : '';

    const exploreTypeId = this.cartPower.ExploretypeId ? `${this.cartPower.ExploretypeId}` : '';
    const powerTypeId = this.cartPower.PowertypeId ? `${this.cartPower.PowertypeId}` : '';

    const categoryIds = [exploreTypeId, powerTypeId].filter((id) => id).join(',');

    const subcategoryIds = this.cartPower.items.map((item) => item.cardId).join(',');

    let mazayaType = 0;
    if (this.cartPower.powerType === "Mazaya Power" && this.cartPower.exploreType === "Mazaya Explore") {
      mazayaType = 4;
    } else if (this.cartPower.powerType === "Mazaya Power") {
      mazayaType = 3;
    } else if (this.cartPower.exploreType === "Mazaya Explore") {
      mazayaType = 2;
    }
    const itemControl = this.myForm.get('items.0');

    if (!itemControl) {
      this._notification.error("", "No members found.");
      return;
    }
    const formValue = itemControl.value;
    const tabbyPaymentData: TabbyPaymentRequest = {
      additional_count: 0,
      mazayaecarddetails: this.cartPower.items.map((item) => ({
        firstname: formValue.firstName,
        lastname: formValue.lastName,
        email: formValue.email,
        dob: formValue.dob,
        relation: formValue.relation,
        gender: formValue.gender,
        sub_type: item.cardName,
        mazayaEcardmainId: 0,
      })),
      mazayaecardmain: {
        category_id: Number(categoryIds),
        vat: this.totalVat,
        amount: this.totalAmount,
        currency: currency,
        id: Number(categoryIds),
        subcategoryIds: subcategoryIds,
      },
      name: "Family",
      booking_id: transaction_id,
      description: "Mazaya Ecard Payment",
    };


    console.log("TabbyPaymentData:", tabbyPaymentData);

    console.log("Tabby Payment Data:", tabbyPaymentData);


    this._mazayaPlusService.createPayment(tabbyPaymentData).subscribe(
      (res) => {

        console.log("Tabby payment response:", res);
        const paymentUrl = res.data;
        window.location.href = paymentUrl;

      },
      (error) => {
        console.error("Tabby payment request failed:", error);
        // if (error.statusCode === 400 && error.error?.data === "order_amount_too_high") {

        this._notification.error("High Amount", "Order amount is too high.");
        // } else {
        //   this._notification.error("Payment Failed", "An error occurred during payment processing.");
        // }
      }
    );
  }
  onApplePayButtonClicked(event: Event): void {
    if (!window.ApplePaySession) {
      this._notification.error('', 'Apple Pay is not supported on this device.');
      return;
    }
  
    const request = {
      countryCode: 'AE',
      currencyCode: 'AED',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      total: {
        label: 'Your Purchase',
        type: 'final',
        amount: this.totalAmount+this.totalVat,
      },
    };
  
    const session = new ApplePaySession(3, request);
  
    session.onvalidatemerchant = async (event) => {
      try {
        const response = await this.http
          .post<ApplePayResponse>('https://mazaya-utility.gewaninfotech.com/utility/1.0/payfort/apple-pay/request-session', { url: event.validationURL })
          .toPromise();
  
        console.log({
          log: "onvalidatemerchant", response
        });
  
        // Access the response.data correctly
        const merchantSession = response.data;
  
        if (!merchantSession) {
          throw new Error('Invalid merchant session data.');
        }
  
        session.completeMerchantValidation(merchantSession);
      } catch (error) {
        console.error('Merchant validation failed:', error);
        this._notification.error('', 'Merchant validation failed.');
      }
    };
  
    session.onpaymentauthorized = async (event) => {
      console.log({
        log: "onpaymentauthorized", token: event.payment.token
      });
      const paymentData = {
        merchant_reference: 'merchantTest-100124',
        amount: this.totalAmount + this.totalVat,
        currency: 'AED',
        language: 'en',
        customer_email: 'sneha.ittoop@gewaninfotech.com',
        apple_data: event.payment.token.paymentData.data,
        apple_signature: event.payment.token.paymentData.signature,
        apple_header: {
          apple_transactionId: event.payment.token.paymentData.header.transactionId,
          apple_ephemeralPublicKey: event.payment.token.paymentData.header.ephemeralPublicKey,
          apple_publicKeyHash: event.payment.token.paymentData.header.publicKeyHash,
        },
        apple_paymentMethod: {
          apple_displayName: event.payment.token.paymentMethod.displayName,
          apple_network: event.payment.token.paymentMethod.network,
          apple_type: event.payment.token.paymentMethod.type,
        },
        recurring_mode: 'UNSCHEDULED',
      };
      console.log({
        log: "onpaymentauthorized - payment data parameter", paymentData
      });
      try {
        const response = await this.http
          .post('https://mazaya-utility.gewaninfotech.com/utility/1.0/payfort/apple-pay/purchase', paymentData)
          .toPromise();
  
        console.log({
          log: "onpaymentauthorized - purchase request", response
        });
  
        // Handle success
        const result = { status: ApplePaySession.STATUS_SUCCESS };
        session.completePayment(result);
        this._notification.success('', 'Payment completed successfully.');
      } catch (error) {
        console.error('Error in onpaymentauthorized');
        console.error('Purchase API failed:', error);
  
        // Handle failure
        const result = { status: ApplePaySession.STATUS_FAILURE };
        session.completePayment(result);
        this._notification.error('', 'Payment failed.');
      }
    };
  
    session.onpaymentmethodselected = (event) => {
      try {
        console.log({
          log: "onpaymentmethodselected"
        });
  
        const update = {
          newTotal: {
            label: 'Your Purchase',
            type: 'final',
            amount: this.totalAmount+this.totalVat,
          },
        };
        session.completePaymentMethodSelection(update);
      } catch (error) {
        console.error('Error in onpaymentmethodselected');
        console.error(error);
      }
    };
  
    session.oncanceled = () => {
      console.log('Payment cancelled');
      this._notification.info('', 'Payment was cancelled.');
    };
  
    session.begin();
  }
  
  

  // onApplePayButtonClicked(): Promise<void> {
  //   if (!window.ApplePaySession) {
  //     this._notification.error('', 'Apple Pay is not supported on this device.');
  //     return;
  //   }

  //   const request: ApplePayPaymentRequest = {
  //     countryCode: 'AE',
  //     currencyCode: 'AED',
  //     merchantCapabilities: ['supports3DS'],
  //     supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
  //     total: {
  //       label: 'Your Purchase',
  //       type: 'final',
  //       amount: this.totalAmount + this.totalVat,
  //     },
  //   };

  //   const session = new ApplePaySession(3, request);

  //   session.onvalidatemerchant = async (event) => {
  //     console.log("event",event)
  //     try {
  //       const response = await this.http
  //         .post<ApplePayMerchantSessionResponse>(
  //           'https://mazaya-utility.gewaninfotech.com/utility/1.0/payfort/apple-pay/request-session',
  //           { url: event.validationURL }
  //         )
  //         .toPromise();

  //       const merchantSession = response.data;
  //       console.log("data",merchantSession )
  //       if (!merchantSession) {
  //         throw new Error('Invalid merchant session data.');
  //       }

  //       session.completeMerchantValidation(merchantSession);
  //     } catch (error) {
  //       console.error('Merchant validation failed:', error);
  //       this._notification.error('', 'Merchant validation failed.');
  //     }
  //   };
  //   session.onpaymentmethodselected = (event) => {
  //     const update = {
  //       newTotal: {
  //         label: 'Your Purchase',
  //         type: 'final',
  //         amount:this.totalAmount+this.totalVat,
  //       },
  //     };
  //     session.completePaymentMethodSelection(update);
  //   };


  //   session.onshippingmethodselected = (event) => {

  //     console.log('onshippingmethodselected')
  //     console.log(event)
  //     const update = {};
  //     session.completeShippingMethodSelection(update);
  //   };
  //   session.onshippingcontactselected = (event) => {

  //     console.log('onshippingcontactselected')
  //     console.log(event);
  //     const update = {};
  //     session.completeShippingContactSelection(update);
  //   };
  //   session.onpaymentauthorized = async (event) => {

  //     console.log('Payment token:', event.payment.token);

  //     const paymentData = {
  //       merchant_reference: 'merchantTest-100124',
  //       amount: this.totalAmount+this.totalVat,
  //       currency: 'AED',
  //       language: 'en',
  //       customer_email: 'sneha.ittoop@gewaninfotech.com',
  //       apple_data: event.payment.token.paymentData.data,
  //       apple_signature: event.payment.token.paymentData.signature,
  //       apple_header: {
  //         apple_transactionId: event.payment.token.paymentData.header.transactionId,
  //         apple_ephemeralPublicKey: event.payment.token.paymentData.header.ephemeralPublicKey,
  //         apple_publicKeyHash: event.payment.token.paymentData.header.publicKeyHash,
  //       },
  //       apple_paymentMethod: {
  //         apple_displayName: event.payment.token.paymentMethod.displayName,
  //         apple_network: event.payment.token.paymentMethod.network,
  //         apple_type: event.payment.token.paymentMethod.type,
  //       },
  //       recurring_mode: 'UNSCHEDULED',
  //     };

  //     try {
  //       const response = await this.http
  //         .post('https://mazaya-utility.gewaninfotech.com/utility/1.0/payfort/apple-pay/purchase', paymentData)
  //         .toPromise();

  //       console.log('Purchase API Response:', response);

  //       // Handle success
  //       const result = { status: ApplePaySession.STATUS_SUCCESS };
  //       session.completePayment(result);
  //       this._notification.success('', 'Payment completed successfully.');
  //     } catch (error) {
  //       console.error('Purchase API failed:', error);

  //       // Handle failure
  //       const result = { status: ApplePaySession.STATUS_FAILURE };
  //       session.completePayment(result);
  //       this._notification.error('', 'Payment failed.');
  //     }
  //   };
  //   session.oncouponcodechanged = (event) => {
  //     // Define ApplePayCouponCodeUpdate
  //     const newTotal = calculateNewTotal(event.couponCode);
  //     const newLineItems = calculateNewLineItems(event.couponCode);
  //     const newShippingMethods = calculateNewShippingMethods(
  //       event.couponCode
  //     );
  //     const errors = calculateErrors(event.couponCode);

  //     session.completeCouponCodeChange({
  //       newTotal: newTotal,
  //       newLineItems: newLineItems,
  //       newShippingMethods: newShippingMethods,
  //       errors: errors,
  //     });
  //   };
  //   session.oncanceled = () => {
  //     console.log('Payment cancelled');
  //     this._notification.info('', 'Payment was cancelled.');
  //   };

  //   session.begin();
  // }

  // calculateTotalAmount(): number {
  //   const totalAmount = 100; // Replace with your logic
  //   const totalVat = 5; // Replace with your logic
  //   return totalAmount + totalVat;
  // }
  // calculateNewTotal(couponCode: string): ApplePayLineItem {
  //   // Implement logic to calculate the new total based on the coupon code
  //   return {
  //     label: 'Total',
  //     amount: '10.00', // Example amount
  //   };
  // }

  // calculateNewLineItems(couponCode: string): ApplePayLineItem[] {
  //   // Implement logic to calculate the new line items based on the coupon code
  //   return [
  //     {
  //       label: 'Item 1',
  //       amount: '5.00',
  //     },
  //     {
  //       label: 'Item 2',
  //       amount: '5.00',
  //     },
  //   ];
  // }

  // calculateNewShippingMethods(couponCode: string): ApplePayShippingMethod[] {
  //   // Implement logic to calculate the new shipping methods based on the coupon code
  //   return [
  //     {
  //       label: 'Standard Shipping',
  //       amount: '0.00',
  //       detail: 'Delivers in 5-7 days',
  //       identifier: 'standard',
  //     },
  //   ];
  // }

  // calculateErrors(couponCode: string): ApplePayError[] {
  //   // Implement logic to determine any errors related to the coupon code
  //   if (couponCode === 'INVALID') {
  //     return [
  //       {
  //         code: 'couponCodeInvalid',
  //         message: 'The coupon code is invalid.',
  //       },
  //     ];
  //   }
  //   return [];
  // }


  // applePayMethod(){

  //   this._router.navigate([`/mazaya-plus/apple-pay`]);
  // }

  onSubmit() {
    this.currentMember = 1
    this.submitted = true

    if (!this.selectedPaymentMethod) {
      this.showError = true;
      this._notification.error("", "Please select a payment method before proceeding.");
      return;
    } else {
      this.showError = false;
    }

    // if (this.selectedPaymentMethod === 'ApplePay') {

    //   if (this.applePayButton) {
    //     this.applePayButton.nativeElement.disabled = false;

    //     this.applePayButton.nativeElement.click();
    //   }
    // }
    if (this.totalAmount === 0) {
      this._notification.error("", " Please add a member");
      return
    }

    const itemsArray = this.myForm.get('items') as FormArray;

    // Loop through each item in the FormArray
    itemsArray.controls.forEach((item: FormGroup, index) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const email = item.get('email')?.value;
      console.log("email", email.length)
      if (email && !emailPattern.test(email)) {
        this._notification.error("Failed", `Invalid email at position ${index + 1}`);
        return
      }

      const phoneNumber = item.get('phoneNumber')?.value;
      if (phoneNumber && phoneNumber.length != 9) {
        this._notification.error("Failed", `Mobile number must be 9 digit at postion ${index + 1}`);
        return
      }
    })
    let isZeroPosError: boolean = false
    const firstGroup = this.myForm.get('items.0') as FormGroup;

    if (firstGroup) {
      Object.keys(firstGroup.controls).forEach((key, index) => {
        if (this.myForm.get('items.0.dob').value !== '' && key == 'dob') {
          isZeroPosError = true
          return
        }
        const control = firstGroup.get(key);
        if (control?.invalid) {

          console.log(`${key} is invalid`, control.errors);
        }
      });
    }

    if (this.myForm.get('items.0').valid && this.myForm.get('agreeConditions')?.valid) {
      let request: Request = {
        "additional_count": 0,
        "mazayaecarddetails": [],
        "mazayaecardmain": {
          "amount": 0,
          "currency": "",
          "category_id": 0,
          "subcategoryIds": "",
          "vat": 0
        }
      }
      console.log(this.cartItemV2Data)
      if (this.isV2) {
        const cards = this.cartItemV2Data[0]?.membershipTypes.filter(i => i.count != 0)
        this.upgradSubIds = cards.map(card => card.cardId).join(',');

        request.additional_count = this.cartItemV2Data[0]?.membershipTypes.length == 2 ? this.cartItemV2Data[0].membershipTypes[1].count : 0
        request.mazayaecarddetails = this.myForm.value.items,
          request.mazayaecardmain.amount = this.totalAmount,
          request.mazayaecardmain.currency = this.cartItemV2Data[0]?.membershipTypes[0].currency,
          request.mazayaecardmain.category_id = this.cartItemV2Data[0]?.typeId,
          request.mazayaecardmain.subcategoryIds = this.subcategoryIds,
          request.mazayaecardmain.vat = this.totalVat
      } else {
        request.additional_count = this.cartItemData?.subCategory.length == 2 ? this.cartItemData.subCategory[1].count : 0
        request.mazayaecarddetails = this.myForm.value.items,
          request.mazayaecardmain.amount = this.totalAmount,
          request.mazayaecardmain.currency = this.cartItemData?.subCategory[0].currency,
          request.mazayaecardmain.category_id = this.cartItemData?.categoryId,
          request.mazayaecardmain.subcategoryIds = this.subcategoryIds,
          request.mazayaecardmain.vat = this.totalVat
      }


      request.mazayaecarddetails[0].firstName = request.mazayaecarddetails[0].firstName.trim();
      request.mazayaecarddetails[0].lastName = request.mazayaecarddetails[0].lastName.trim();
      request.mazayaecarddetails[0].gender = request.mazayaecarddetails[0].gender;
      request.mazayaecarddetails.forEach((element, index) => {

        const date = moment(request.mazayaecarddetails[index].dob);
        const formattedDob = date.format('YYYY-MM-DD');
        request.mazayaecarddetails[index].dob = formattedDob;

        if (request.mazayaecarddetails[index].phoneNumber != '')
          request.mazayaecarddetails[index].phoneNumber = this.checkStartsWith971(request.mazayaecarddetails[index].phoneNumber) ? request.mazayaecarddetails[index].phoneNumber : '971' + request.mazayaecarddetails[index].phoneNumber;
      });

      this._mazayaPlusService.createEcard(request)
        .subscribe((res) => {
          console.log(res)
          if (res.data != 0) {

            const lastTransaction: { type: string, transactionId: string } = {
              type: this.cartType,
              transactionId: res.data

            }
            localStorage.setItem('lastTransaction', JSON.stringify(lastTransaction))
            if (this.selectedPaymentMethod === 'Credit/Debit Card') {

              this.handlePayment(res.data, request.mazayaecarddetails[0].email);
            } else if (this.selectedPaymentMethod === 'Tabby') {

              this.createPaymentWithTabby(res.data);
            }
            // else if (this.selectedPaymentMethod === 'ApplePay') {
            //   this.onApplePayButtonClicked();

            // }


          } else {
            this._notification.error("Failed", " Please enter a valid Email");
          }


        })

    } else {
      const firstGroup = this.myForm.get('items.0') as FormGroup;

      if (firstGroup) {
        Object.keys(firstGroup.controls).forEach(key => {
          const control = firstGroup.get(key);
          if (control?.invalid) {

            console.log(`${key} is invalid`, control.errors);
          }
        });
      }
    }

  }
  createPaymentWithTabby(transaction_id: number) {


    console.log("createPaymentWithTabby called with transaction_id:", transaction_id);
    console.log("Form items:", this.myForm.value.items);
    console.log("Cart Item V2 Data:", this.cartItemV2Data);


    let tabbyPaymentData: TabbyPaymentRequest;

    if (this.isV2) {

      tabbyPaymentData = {
        additional_count: this.cartItemV2Data[0]?.membershipTypes.length === 2
          ? this.cartItemV2Data[0].membershipTypes[1].count
          : 0,
        mazayaecarddetails: this.myForm.value.items.map((item: any) => ({
          email: item.email.trim(),
          dob: moment(item.dob).format('YYYY-MM-DD'),
          relation: item.relation,
          lastname: item.lastName.trim(),
          firstname: item.firstName.trim(),
          sub_type: item.sub_type,
          mazayaEcardmainId: 0,
          gender: item.gender,

        })),
        mazayaecardmain: {
          category_id: this.cartItemV2Data[0]?.typeId,
          vat: this.totalVat,
          amount: this.totalAmount,
          currency: this.cartItemV2Data[0]?.membershipTypes[0]?.currency,
          id: this.cartItemV2Data[0]?.typeId,
          subcategoryIds: this.subcategoryIds,
        },
        name: "Family Membership",
        booking_id: transaction_id,
        description: "Mazaya Ecard Payment",
      };
    } else {

      tabbyPaymentData = {
        additional_count: this.cartItemData?.subCategory.length === 2
          ? this.cartItemData.subCategory[1].count
          : 0,
        mazayaecarddetails: this.myForm.value.items.map((item: any) => ({
          email: item.email.trim(),
          dob: moment(item.dob).format('YYYY-MM-DD'),
          relation: item.relation,
          lastname: item.lastName.trim(),
          firstname: item.firstName.trim(),
          sub_type: item.sub_type,
          mazayaEcardmainId: 0,
          gender: item.gender,

        })),
        mazayaecardmain: {
          category_id: this.cartItemData?.categoryId,
          vat: this.totalVat,
          amount: this.totalAmount,
          currency: this.cartItemData?.subCategory[0]?.currency,
          id: this.cartItemData?.categoryId,
          subcategoryIds: this.subcategoryIds,
        },
        name: "Family Membership",
        booking_id: transaction_id,
        description: "Mazaya Ecard Payment",
      };
    }


    console.log("Tabby Payment Data:", tabbyPaymentData);
    console.log("Tabby Payment Data:", JSON.stringify(tabbyPaymentData, null, 2));


    this._mazayaPlusService.createPayment(tabbyPaymentData).subscribe(

      (res) => {
        console.log("Tabby payment response:", res);
        const paymentUrl = res.data;

        window.location.href = paymentUrl;
      },
      (error) => {

        console.error("Tabby payment request failed:", error);

        this._notification.error("High Amount", "Order amount is too high.");

      }
    );
  }

  payment(data: any): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    let options = { headers: headers };
    return this.http.post<any>(
      'https://sbpaymentservices.payfort.com/FortAPI/paymentApi',
      data,
      options,
    )
  }

  checkout() {
    const currentTimestamp = new Date().getTime();
    const data = {
      payfortactlink: 'https://sbcheckout.payfort.com/FortAPI/paymentPage',
      payfortcommand: 'AUTHORIZATION',
      payfortaccesscode: 'pXOgNQvchAF3hPktmdNN',
      payfortmerchantidentifier: 'uBtRIcqH',
      totpayamount: '2000',
      payfortcurrency: 'AED',
      payfortlanguage: 'en',
      customeremail: 'test@gmail.com',
      bookrefnum: currentTimestamp,
      customer_ip: '103.178.205.183',
      signature: '',
    };

    var text = `cnsduifgwegfyaccess_code=${data.payfortaccesscode}amount=${data.totpayamount}command=${data.payfortcommand}currency=${data.payfortcurrency}customer_email=${data.customeremail}customer_ip=${data.customer_ip}language=${data.payfortlanguage}merchant_identifier=${data.payfortmerchantidentifier}merchant_reference=${data.bookrefnum}cnsduifgwegfy`;
    const sha256Hash = this.calculateSHA256(text);
    data.signature = sha256Hash;


    this._mazayaPlusService.payment(data).subscribe(res => {

    })
    // this.http
    //   .post<any>(
    //     'https://sbpaymentservices.payfort.com/FortAPI/paymentApi',
    //     data,
    //     options,
    //   )
    //   .pipe(
    //     catchError((error: any, caught: Observable<any>): Observable<any> => {
    //       this.errorMessage = error.message;
    //       return of();
    //     }),
    //   )
    //   .subscribe((data) => {
    //     this.response = data;
    //   });
  }
  handlePowerPayment(transaction_id: number, email: string) {

    this.store.select(AuthState.token).subscribe((token) => {
      //  this.token = "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vaHViLm1hdHJpeC5jb20vIiwic3ViIjoieE9RTVY0aG0xeWs9IiwiZXhwIjoxNjk4OTIzMjA5fQ.R0voJ9K_7dyxF3vM9B7e7pP8z0YSD4GwASRFgeoxAo4";
    })


    const isMazayaPlus = localStorage.getItem('auth.email');


    const payfortactlink = environment.payfortactlink;
    const payfortcommand = environment.payfortcommand;
    const payfortaccesscode = environment.payfortaccesscode;
    const payfortmerchantidentifier = environment.payfortmerchantidentifier;
    const shaRequest = environment.shaRequest;




    const totpayamount = this.roundAmount(this.totalAmount + this.totalVat) * 100;
    const payfortcurrency = 'AED';
    const payfortlanguage = 'en';
    const customeremail = email;
    const currentTimestamp = new Date().getTime();
    const bookrefnum = transaction_id;
    const host_url = `${window.location.origin}/`;
    //const host_url = "http://localhost:4200/";
    const resource = environment.serverUrl
    const return_url = `${resource}/MazayaPowerExplore/PowerExplorePaymentwebstatus?paymentGwayId=${bookrefnum}&successReturnUrl=${host_url}home/payment-success&failureReturnUrl=${host_url}home/payment-failure`

    var text = `${shaRequest}access_code=${payfortaccesscode}amount=${totpayamount}command=${payfortcommand}currency=${payfortcurrency}customer_email=${customeremail}language=${payfortlanguage}merchant_identifier=${payfortmerchantidentifier}merchant_reference=${bookrefnum}return_url=${return_url}${shaRequest}`
    const sha256Hash = this.calculateSHA256(text);

    this.formContent = `<form #form method="post" action="${payfortactlink}">
    <input type="hidden" name="command" value="${payfortcommand}" id="command" />
    <input type="hidden" name="access_code" value="${payfortaccesscode}" id="access_code" />
    <input type="hidden" name="merchant_identifier" value="${payfortmerchantidentifier}" id="merchant_identifier" />
    <input type="hidden" name="amount" value="${totpayamount}" id="amount" />
    <input type="hidden" name="currency" value="${payfortcurrency}" id="currency" />
    <input type="hidden" name="language" value="${payfortlanguage}" id="language" />
    <input type="hidden" name="customer_email" value="${customeremail}" id="customeremail" />
    <input type="hidden" name="signature" value="${sha256Hash}" id="signature" />
    <input type="hidden" name="return_url" value="${return_url}" id="return_url" />
    
    <input type="hidden" name="merchant_reference" value="${bookrefnum}" id="merchant_reference" />
    <input type="submit" value="PAY" id="btnGoToPG" ref="payBtn" style="display:none;" />
  </form>`
    this.formContainer.nativeElement.innerHTML = this.formContent;
    const form = this.formContainer.nativeElement.querySelector('form');

    form.submit();
  }

  handlePayment(transaction_id: number, email: string) {


    this.store.select(AuthState.token).subscribe((token) => {
      //  this.token = "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vaHViLm1hdHJpeC5jb20vIiwic3ViIjoieE9RTVY0aG0xeWs9IiwiZXhwIjoxNjk4OTIzMjA5fQ.R0voJ9K_7dyxF3vM9B7e7pP8z0YSD4GwASRFgeoxAo4";
    })


    const isMazayaPlus = localStorage.getItem('auth.email');


    const payfortactlink = environment.payfortactlink;
    const payfortcommand = environment.payfortcommand;
    const payfortaccesscode = environment.payfortaccesscode;
    const payfortmerchantidentifier = environment.payfortmerchantidentifier;
    const shaRequest = environment.shaRequest;




    const totpayamount = this.roundAmount(this.totalAmount + this.totalVat) * 100;
    const payfortcurrency = 'AED';
    const payfortlanguage = 'en';
    const customeremail = email;
    const currentTimestamp = new Date().getTime();
    const bookrefnum = transaction_id;
    const host_url = `${window.location.origin}/`;
    //const host_url = "http://localhost:4200/";
    const resource = environment.serverUrl
    const return_url = `${resource}/Paymentweb/Paymentwebstatus?paymentGwayId=${bookrefnum}&successReturnUrl=${host_url}home/payment-success&failureReturnUrl=${host_url}home/payment-failure`

    var text = `${shaRequest}access_code=${payfortaccesscode}amount=${totpayamount}command=${payfortcommand}currency=${payfortcurrency}customer_email=${customeremail}language=${payfortlanguage}merchant_identifier=${payfortmerchantidentifier}merchant_reference=${bookrefnum}return_url=${return_url}${shaRequest}`
    const sha256Hash = this.calculateSHA256(text);

    this.formContent = `<form #form method="post" action="${payfortactlink}">
    <input type="hidden" name="command" value="${payfortcommand}" id="command" />
    <input type="hidden" name="access_code" value="${payfortaccesscode}" id="access_code" />
    <input type="hidden" name="merchant_identifier" value="${payfortmerchantidentifier}" id="merchant_identifier" />
    <input type="hidden" name="amount" value="${totpayamount}" id="amount" />
    <input type="hidden" name="currency" value="${payfortcurrency}" id="currency" />
    <input type="hidden" name="language" value="${payfortlanguage}" id="language" />
    <input type="hidden" name="customer_email" value="${customeremail}" id="customeremail" />
    <input type="hidden" name="signature" value="${sha256Hash}" id="signature" />
    <input type="hidden" name="return_url" value="${return_url}" id="return_url" />
    
    <input type="hidden" name="merchant_reference" value="${bookrefnum}" id="merchant_reference" />
    <input type="submit" value="PAY" id="btnGoToPG" ref="payBtn" style="display:none;" />
  </form>`
    this.formContainer.nativeElement.innerHTML = this.formContent;
    const form = this.formContainer.nativeElement.querySelector('form');

    form.submit();
  }

  calculateSHA256(input: string): string {
    const hash = CryptoJS.SHA256(input);
    return hash.toString(CryptoJS.enc.Hex);
  }

  addBreakLine(name: string): string {
    return name.replace(/ /g, '<br/>')
  }
  calculateMaxDate(): Date {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setFullYear(today.getFullYear() - 18);
    return maxDate;
  }
  initUserInfo() {
    this._mazayaPlusService.getUserInfo().subscribe((res) => {
      this.userInfo = res


      setInterval(() => {
        const localStorageValue = localStorage.getItem('cart');

        if (localStorageValue && localStorageValue !== "undefined") {
          const data = JSON.parse(localStorageValue);
          this.cartItemData = data
          this.amountCalculation();
        }

      }, 1000);

      const storedData = localStorage.getItem('cart');


      if (storedData && storedData !== "undefined") {
        this.isV2 = false;
        this.cartType = "v1-cart"
        const data = JSON.parse(storedData);
        this.cartItemData = data

      }



      const mazayaPlusV2Data = localStorage.getItem('v2-cart');
      if (mazayaPlusV2Data && mazayaPlusV2Data !== "undefined") {
        this.isV2 = true;
        this.cartType = "v2-cart"
        const data = JSON.parse(mazayaPlusV2Data);
        this.cartItemV2Data = data
        console.log("cartItemV2Data", this.cartItemV2Data)
        // this.isOldToNew = this.cartItemV2Data[0].isOldToNew
        this.amountCalculationV2();
      }
      if (this.cartType === "v1-cart") {

        this.amountCalculation();
        let mainCount = 0;
        let adultCount = 0;
        let childCount = 0;
        let singleCount = 0;


        for (let i = 0; i < this.cartItemData.subCategory.length; i++) {

          if (this.cartItemData.subCategory[i].name === "FAMILY MEMBERSHIP") {
            mainCount = this.cartItemData.subCategory[i].totalcount;
          } else if (this.cartItemData.subCategory[i].name === "SINGLE MEMBERSHIP") {
            singleCount = singleCount + this.cartItemData.subCategory[i].count;
          } else if (this.cartItemData.subCategory[i].name === "ADDITIONAL ADULT MEMBERSHIP") {
            adultCount = adultCount + this.cartItemData.subCategory[i].count;
          }
          else if (this.cartItemData.subCategory[i].name === "ADDITIONAL CHILD MEMBERSHIP") {
            childCount = childCount + this.cartItemData.subCategory[i].count;
          }
        }
        for (let i = 0; i < mainCount; i++) {
          if (i <= 3) {
            this.itemControls.push(this.createItemFormGroup1("FAMILY", 'Child'));
          } else {
            this.itemControls.push(this.createItemFormGroup1("FAMILY", 'Adult'));
          }

        }
        for (let i = 0; i < singleCount; i++) {
          this.itemControls.push(this.createItemFormGroup1("SINGLE", 'Adult'));
        }
        for (let i = 0; i < adultCount; i++) {
          this.itemControls.push(this.createItemFormGroup1("ADDITIONAL ADULT", 'Adult'));
        }
        for (let i = 0; i < childCount; i++) {
          this.itemControls.push(this.createItemFormGroup1("ADDITIONAL CHILD", 'Child'));
        }
        let famArray = this.cartItemData.subCategory.find(item => item.name === "FAMILY MEMBERSHIP");
        let singleArray = this.cartItemData.subCategory.find(item => item.name === "SINGLE MEMBERSHIP");
        if (famArray === undefined || singleArray === undefined) {
          if (this.cartItemData.subCategory[0].sub_type === "ADDITIONAL CHILD") {
            this.isChildOnly = true;
          }
          this.myForm.get('items.0').setValue({
            firstName: "",
            lastName: "",
            email: "",
            dob: "",
            relation: '',
            gender: '',
            phoneNumber: '',
            sub_type: this.cartItemData.subCategory[0].sub_type
          });

        }


        // for (let i = 1; i < totalCount; i++) {
        //   this.addItem();
        // }
        for (let i = 0; i < this.cartItemData.subCategory.length; i++) {
          if (i != 0)
            this.subcategoryIds = this.subcategoryIds + "," + this.cartItemData.subCategory[i].id.toString()
          else
            this.subcategoryIds = this.cartItemData.subCategory[i].id.toString()
        }
      } if (this.cartType === "v2-cart") {
        this._mazayaPlusService.getUserInfo().subscribe((res) => {
          this.userInfo = res
          this.createFormV2()
        })

      }

      const powerAndExplore = localStorage.getItem('cartPower');
      if (powerAndExplore && powerAndExplore !== "undefined") {
        this.cartType = "power-cart"
        this.isChildOnly = false;
        this.loadCart()


      }

      const currentUrl = window.location.pathname;

      if (currentUrl == "/mazaya-plus/upgade-check-out/0" || currentUrl == "/mazaya-plus/upgade-check-out/1") {

        this.upgradeMembership()
      }
      if (this.myForm.value.items.length) {

      }
      this.getUserProfile();
      this.initRelationship()
    })
  }
  deleteCart() {
    localStorage.removeItem('cart');
    this._router.navigate(["/mazaya-plus"]);
  }
  deleteSubCategory(i: number) {
    this.cartItemData.subCategory.splice(i, 1);
    if (this.cartItemData?.subCategory[0]?.sub_type === "ADDITIONAL CHILD") {
      this.isChildOnly = true;
    }

    if (this.cartItemData.subCategory.length == 0) {

      localStorage.setItem('cart', JSON.stringify([]));
      localStorage.removeItem('cart');

      this._router.navigate(["/mazaya-plus"]);

    } else {
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      for (let i = 0; i < this.cartItemData.subCategory.length; i++) {
        if (i != 0)
          this.subcategoryIds = this.subcategoryIds + "," + this.cartItemData.subCategory[i].id.toString()
        else
          this.subcategoryIds = this.cartItemData.subCategory[i].id.toString()
      }
      this.amountCalculation();
    }

  }
  initRelationship() {
    // if (this.userInfo?.mazayaCardCount === 0) {
    //   this.relations = [
    //     { value: 'Myself', viewValue: 'Myself' },
    //     { value: 'Father', viewValue: 'Father' },
    //     { value: 'Mother', viewValue: 'Mother' },
    //     { value: 'Son', viewValue: 'Son' },
    //     { value: 'Daughter', viewValue: 'Daughter' },
    //     { value: 'Spouse', viewValue: 'Spouse' },
    //     { value: 'Other', viewValue: 'Other' },
    //   ]
    // } else {
    //   this.relations = [
    //     { value: 'Father', viewValue: 'Father' },
    //     { value: 'Mother', viewValue: 'Mother' },
    //     { value: 'Son', viewValue: 'Son' },
    //     { value: 'Daughter', viewValue: 'Daughter' },
    //     { value: 'Spouse', viewValue: 'Spouse' },
    //     { value: 'Other', viewValue: 'Other' },
    //   ]
    // }
  }
  onInputTrim(fieldName: string) {
    const control = this.myForm.get(`items.0.${fieldName}`);
    if (!control) return;

    const value = control.value;
    const trimmedValue = value.trimStart();
    if (value !== trimmedValue) {
      control.setValue(trimmedValue);
    }
  }
  roundAmount(amount: number): number {
    return parseFloat(amount.toFixed(2))
  }
  onGenderChange(event: MatRadioChange) {
    this.userGender = event.value;
  }

  amountCalculationV2() {
    this.totalVat = 0;
    this.totalAmount = 0;

    for (let i = 0; i < this.cartItemV2Data[0].membershipTypes.length; i++) {
      this.totalVat = this.totalVat + (this.cartItemV2Data[0].membershipTypes[i].vat) * this.cartItemV2Data[0].membershipTypes[i].selectedCount
      this.totalAmount = this.totalAmount + (this.cartItemV2Data[0].membershipTypes[i].amount) * this.cartItemV2Data[0].membershipTypes[i].selectedCount

    }
    this.grandTotalAmount = this.totalAmount + this.totalVat
  }
  onIncrementV2(i: number) {
    if (this.cartItemV2Data[0].membershipTypes[i].selectedCount != this.cartItemV2Data[0].membershipTypes[i].count) {
      this.cartItemV2Data[0].membershipTypes[i].selectedCount++
      this._storageService.setMazayaMembershipV2(this.cartItemV2Data)
      this.amountCalculationV2()
      if (this.cartItemV2Data[0].membershipTypes[i].cardName === "ADDITIONAL ADULT MEMBERSHIP") {
        this.itemControls.push(this.createItemFormGroup1("ADDITIONAL ADULT", 'Adult'));
      } else if (this.cartItemV2Data[0].membershipTypes[i].cardName === "ADDITIONAL CHILD MEMBERSHIP") {
        this.itemControls.push(this.createItemFormGroup1("ADDITIONAL CHILD", 'Child'));
      }
    } else {
      this._notification.error(
        "",
        "Maximum count reached"
      );
    }

  }

  onDecrementV2(i: number) {
    if (this.cartItemV2Data[0].membershipTypes[i].selectedCount !== 0) {
      this.cartItemV2Data[0].membershipTypes[i].selectedCount--
      this._storageService.setMazayaMembershipV2(this.cartItemV2Data)
      if (this.cartItemV2Data[0].membershipTypes.length > 1 && this.userInfo?.mazayaCardCount != 0) {
        this.removeItem(0)
      } else {
        this.removeItem(this.itemControls.controls.length - 1)
      }

      if (this.cartItemV2Data[0].membershipTypes[i].selectedCount == 0)
        this.deleteSubCategoryV2(i);
      this.amountCalculationV2();
    }
  }
  deleteSubCategoryV2(i: number) {

    this.cartItemV2Data[0].membershipTypes.splice(i, 1);
    if (this.cartItemV2Data[0]?.membershipTypes[0]?.cardName === "ADDITIONAL CHILD MEMBERSHIP") {
      this.isChildOnly = true;
    }

    if (this.cartItemV2Data[0].membershipTypes.length == 0) {

      localStorage.setItem('v2-cart', JSON.stringify([]));
      localStorage.removeItem('v2-cart');

      this._router.navigate(["/mazaya-plus"]);

    } else {

      localStorage.setItem('v2-cart', JSON.stringify(this.cartItemV2Data));
      // this.amountCalculationV2();
      this.itemControls.clear()
      this.createFormV2()
    }

  }
  mobileValidator(mobileNumber: string) {

    if (mobileNumber.length === 9 || mobileNumber.length === 10) {
      this.mobileNumberError = false;
      return true;
    } else {
      this.mobileNumberError = true;
      return false;
    }

  }
  mobileNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value || '';
    if (value.length === 9 || value.length === 10) {
      console.log("dfdf")
      return null; // Valid
    }
    return { invalidMobileNumber: true }; // Invalid
  }
  deleteCartV2() {
    localStorage.removeItem('v2-cart');
    this._router.navigate(["/mazaya-plus"]);
  }

  upgradeMembership() {
    this.isUpgrade = true
    this._mazayaPlusService.getUpgradeMembership(this.userInfo?.isGoldmembership).subscribe((res) => {
      const response = res.data.membershipTypes
      const index = parseInt(this._route.snapshot.params.index)
      this.upgradTo = response[index].typeId
      this.cartItemV2Data = res.data.membershipTypes[index].membershipTypes
      console.log("categoryList", this.cartItemV2Data)
      const cards = this.cartItemV2Data[0]?.cards.filter(i => i.count != 0)
      this.upgradSubIds = cards.map(card => card.cardId).join(',');

      this.cartItemV2Data[0]?.cards.forEach((item, i) => {


        if (i == 0) {
          this.upgradeAmountTotal = this.upgradeAmountTotal + item.amount
          this.totalAmount = this.upgradeAmountTotal + item.amount
          this.upgradeVatTotal = this.upgradeVatTotal + item.vat

        } else {

          this.upgradeAmountTotal = this.upgradeAmountTotal + item.amount * item.count
          this.upgradeVatTotal = this.upgradeVatTotal + item.vat * item.count


        }

      })
      this.totalAmount = this.upgradeAmountTotal
      this.totalVat = this.upgradeVatTotal

    })
  }
  // this._mazayaPlusService.getUserProfile().subscribe(res => {
  //   this.userFirstName = res.firstName;
  //   this.userLastName = res.lastName;
  //   this.userEmail = res.email;
  //   this.phoneNumber = res.phoneNumber.substring(3);
  //   this.gender = res.gender;
  //   if (this.userInfo?.mazayaCardCount === 0) {

  //     this.isCreatedCard = false

  //     this.myForm.get('items.0').setValue({
  //       firstName: res.firstName,
  //       lastName: res.lastName,
  //       email: res.email,
  //       dob: '',
  //       relation: 'Myself',
  //       sub_type: this.myForm.get('items.0.sub_type').value,
  //       phoneNumber: this.phoneNumber,
  //       gender: this.gender
  //     });
  //   } else {
  //     this.isCreatedCard = true;
  //   }
  // })
  upgradeConfirm() {
    if (!this.selectedPaymentMethod) {
      this.showError = true;
      this._notification.error("", "Please select a payment method before proceeding.");
      return;
    } else {
      this.showError = false;
    }
    const rqBody = {
      "amount": this.upgradeAmountTotal,
      "vat": this.upgradeVatTotal,
      "category_id": this.upgradTo,
      "subcategoryIds": this.upgradSubIds
    }

    this._mazayaPlusService.upgradeMembership(rqBody).subscribe((res) => {
      const lastTransaction: { type: string, transactionId: string } = {
        type: "v2-cart",
        transactionId: res.data
      }
      const email = JSON.parse(localStorage.getItem('auth.email'));

      // localStorage.setItem('lastTransaction', JSON.stringify(lastTransaction))
      // this.handlePayment(res.data, email)
      localStorage.setItem('lastTransaction', JSON.stringify(lastTransaction))
      if (this.selectedPaymentMethod === 'Credit/Debit Card') {

        this.handlePayment(res.data, this.userEmail);
      } else if (this.selectedPaymentMethod === 'Tabby') {

        this.createUpgradeWithTabby(res.data);
      }

    })
  }
  upgradeGoldAddOnConfirm() {
    if (!this.selectedPaymentMethod) {
      this.showError = true;
      this._notification.error("", "Please select a payment method before proceeding.");
      return;
    } else {
      this.showError = false;
    }
    const rqBody = {
      "amount": this.upgradeAmountTotal,
      "vat": this.upgradeVatTotal,
      "category_id": this.upgradTo,
      "subcategoryIds": this.upgradSubIds,
      "isgoldaddon": "true"
    }

    this._mazayaPlusService.upgradeToGoldAddOn(rqBody).subscribe((res) => {
      const lastTransaction: { type: string, transactionId: string } = {
        type: "v2-cart",
        transactionId: res.data
      }
      const email = JSON.parse(localStorage.getItem('auth.email'));

      localStorage.setItem('lastTransaction', JSON.stringify(lastTransaction))
      if (this.selectedPaymentMethod === 'Credit/Debit Card') {
        this.handlePayment(res.data, email)
      }
      else if (this.selectedPaymentMethod === 'Tabby') {

        this.createUpgradeWithTabby(res.data);
      }

    })

  }
  createUpgradeWithTabby(transaction_id: number) {
    console.log("createPaymentWithTabby called with transaction_id:", transaction_id);

    this._mazayaPlusService.getUserProfile().subscribe(res => {
      // Extract user details from the response
      const userFirstName = res.firstName;
      const userLastName = res.lastName;
      const userEmail = res.email;
      const phoneNumber = res.phoneNumber.substring(3);
      const gender = res.gender;

      console.log("aaaaaaaaaaaa", this.cartItemV2Data)
      const mazayaecarddetails: MazayaEcardDetail[] = [
        {
          email: userEmail,
          dob: '',
          relation: 'myself',
          lastname: userLastName,
          firstname: userFirstName,
          sub_type: '',
          mazayaEcardmainId: 0,
          gender: gender,
        }
      ];

      const mazayaecardmain: MazayaeEcardMain = {
        category_id: this.upgradTo,
        vat: this.totalVat,
        amount: this.totalAmount,
        currency: this.cartItemV2Data[0]?.cards[0].currency,
        id: this.upgradTo,
        subcategoryIds: this.upgradSubIds,
      };


      const tabbyPaymentData: TabbyPaymentRequest = {
        additional_count: 0,
        mazayaecarddetails: mazayaecarddetails,
        mazayaecardmain: mazayaecardmain,
        name: "Family",
        booking_id: transaction_id,
        description: "Mazaya Ecard Payment",
      };

      console.log("TabbyPaymentData:", tabbyPaymentData);


      this._mazayaPlusService.createPayment(tabbyPaymentData).subscribe(
        (response) => {
          console.log("Tabby payment response:", response);
          const paymentUrl = response.data;
          window.location.href = paymentUrl;
        },
        (error) => {
          console.error("Tabby payment request failed:", error);
        }
      );
    });
  }

  checkStartsWith971(input: string): boolean {
    return input.startsWith("971");
  };
  handleRightArrow() {
    console.log("itemControls.controls", this.itemControls.controls)
    if (this.currentMember !== this.itemControls.controls.length) {
      this.currentMember++;
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'FAMILY') {

        this.isChildOnly = this.currentMember > 2 ? true : false
        this.isNannyOnly = false
        this.filterMemberList(this.isChildOnly)
      }
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'ADDITIONAL ADULT') {
        this.isChildOnly = false
        this.isNannyOnly = false
        this.filterMemberList(this.isChildOnly)
      }
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'ADDITIONAL CHILD') {
        this.isChildOnly = true
        this.isNannyOnly = false
        this.filterMemberList(this.isChildOnly)
      }
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'NANNY SERVICE') {
        this.isChildOnly = false
        this.isNannyOnly = true
        this.filteredMembers = []
      }

    }

  }
  handleLeftArrow() {
    if (this.currentMember !== 1) {
      this.currentMember--;
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'FAMILY') {
        this.isChildOnly = this.currentMember > 2 ? true : false
        this.isNannyOnly = false
        this.filterMemberList(this.isChildOnly)
      }
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'ADDITIONAL ADULT') {
        this.isChildOnly = false
        this.isNannyOnly = false
        this.filterMemberList(this.isChildOnly)
      }
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'ADDITIONAL CHILD') {
        this.isChildOnly = true
        this.isNannyOnly = false
        this.filterMemberList(this.isChildOnly)
      }
      if (this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value == 'NANNY SERVICE') {
        this.isChildOnly = false
        this.isNannyOnly = true
        this.filteredMembers = []
      }
    }

  }
  onMemberTypeChange(event: MatRadioChange) {
    const selectedValue = event.value;

  }
  filterMemberList(isChild: boolean) {
    const temp: any = this.membershipDetails
    this.filteredMembers = []
    temp?.forEach((element, i) => {

      const adult = this.checkAdult(element.dob)

      if (adult && !isChild && element.firstname != '' && i !== 0) {
        console.log("ddddddd", adult, element.dob)
        this.filteredMembers.push(element)
      }
      if (!adult && isChild && element.firstname != '') {
        this.filteredMembers.push(element)
      }

    });

  }
  onChoosenMember(event: MatSelectChange) {
    const value = event.value;
    const index = this.membershipDetails.findIndex(item => item.id === value);
    this.myForm.get(`items.${this.currentMember - 1}`).setValue({
      firstName: this.membershipDetails[index].firstname,
      lastName: this.membershipDetails[index].lastname,
      email: this.membershipDetails[index].email,
      dob: this.membershipDetails[index].dob,
      relation: this.membershipDetails[index].relation,
      gender: this.membershipDetails[index].gender,
      phoneNumber: this.membershipDetails[index].phoneNumber.substring(3),
      sub_type: this.myForm.get(`items.${this.currentMember - 1}.sub_type`).value,
      selection: 'existing',
      profile_image: this.membershipDetails[index].profile_image,
      memberType: this.checkAdult(this.membershipDetails[index].dob) ? 'Adult' : 'Child'
    });
  }
  checkAdult(dob: string): boolean {
    if (!dob) {
      return false;
    }
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    const value = age >= 18;
    return value
  }
}
