<html>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
    <style>
      apple-pay-button {
        --apple-pay-button-width: 90px;
        --apple-pay-button-height: 30px;
        --apple-pay-button-border-radius: 3px;
        --apple-pay-button-padding: 0px 0px;
      
      }
    </style>
    <script crossorigin src="https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js"></script>
  </head>
<div class="payment-container">
  <div class="title-main">Check Out</div>
  <div class="payment-content">
  </div>
  <div class="row">
    <div class="column">
      <div *ngIf="cartType=='v1-cart'" class="payment-card-section">
        <div class="sidebar-checkout">
          <div class="payment-text-mem">Cart Details</div>
          <div class="sidebar-card" *ngFor="let cartItem of cartItemData.subCategory; let i=index; trackBy: trackByFn">
            <div class="side-overlay">
              <img class="side-user" src="assets/images/mazayaPlus/iconCarrier-single.svg" alt="user-sidebar">
            </div>
            <div class="card-content">
              <div class="price-validity">
                Valid Until 31st Dec 2024
              </div>
              <div class="check-header">
                <div class="title">{{cartItem.name}}</div>
                <div class="price-sidebar">{{cartItem.currency}}<sub
                    class="suber-text">{{roundAmount(cartItem.amount)}}</sub></div>
              </div>
              <div class="check-header">
                <div class="para-sidebar">{{cartItem.description}}</div>
                <div class="delete-section"
                  *ngIf="cartItem.name=='FAMILY MEMBERSHIP' || cartItem.name=='SINGLE MEMBERSHIP'">
                  <img class="check-delete" src="assets/images/mazayaPlus/iconDelete.svg" (click)="deleteCart()"
                    alt="side-delete">
                </div>
                <div class="spinner-section-second"
                  *ngIf="cartItem.name!='FAMILY MEMBERSHIP' && cartItem.name!='SINGLE MEMBERSHIP'">
                  <div class="img-user"><img src="assets/images/mazayaPlus/user-filled.svg" alt="user">
                  </div>
                  <div class="img-spinner" (click)="onDecrement(i)"><img src="assets/images/mazayaPlus/minus.svg"
                      alt="minu"></div>
                  <div>{{cartItem.count}}</div>
                  <div class="img-spinner-add" (click)="onIncrement(i)"><img src="assets/images/mazayaPlus/add.svg"
                      alt="plus"></div>
                  <div class="delete-section" (click)="deleteSubCategory(i)">
                    <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="payment-sidebar">
            <div class="d-flex">
              <img src="assets/images/mazayaPlus/cart.svg" alt="cart-img">
              <div class="cart-text-sum">Cart Summary</div>
            </div>
            <div class="payment-text">
              <div>Amount</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalAmount)}}</div>
            </div>
            <div class="payment-text">
              <div>Vat</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalVat)}}</div>
            </div>
            <div class="divider-checkout"></div>

            <div class="payment-text">
              <div>Grand Total</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalAmount+totalVat)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="cartType=='v2-cart'" class="payment-card-section">
        <div class="sidebar-checkout">
          <div class="payment-text-mem">Cart Details</div>
          <div class="sidebar-card"
            *ngFor="let cartItem of cartItemV2Data[0].membershipTypes; let i=index; trackBy: trackByFn">
            <div style="display: flex; gap: 10px;">
              <div style="flex: 0;">
                <img class="side-user" src="assets/images/mazayaPlus/iconCarrier-single.svg" alt="user-sidebar">
              </div>
              <div style="flex: 1;">
                <div class="price-validity">
                  {{cartItem.subscriptionDuration}}
                </div>
                <div class="check-header">

                  <div class="title">{{cartItem.cardName}}</div>
                  <div class="price-sidebar">{{cartItem.currency}}<sub
                      class="suber-text">{{roundAmount(cartItem.amount)}}</sub></div>
                </div>
                <div class="description">
                  {{cartItem.description}}
                </div>
                <div class="check-header">
                  <div class="spinner-section-second"
                    *ngIf="cartItem.cardName=='FAMILY MEMBERSHIP' || cartItem.cardName=='SINGLE MEMBERSHIP'">
                    <img class="check-delete" src="assets/images/mazayaPlus/iconDelete.svg" (click)="deleteCartV2()"
                      alt="side-delete">
                  </div>

                  <div class="spinner-section-second"
                    *ngIf="cartItem.cardName!='FAMILY MEMBERSHIP' && cartItem.cardName!='SINGLE MEMBERSHIP'">
                    <div class="img-user"><img src="assets/images/mazayaPlus/user-filled.svg" alt="user">
                    </div>
                    <div class="img-spinner" (click)="onDecrementV2(i)"><img src="assets/images/mazayaPlus/minus.svg"
                        alt="minu"></div>
                    <div>{{cartItem.selectedCount}}</div>
                    <div class="img-spinner-add" (click)="onIncrementV2(i)"><img src="assets/images/mazayaPlus/add.svg"
                        alt="plus"></div>
                    <div class="delete-section" (click)="deleteSubCategoryV2(i)">
                      <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg"
                        alt="side-delete">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="payment-sidebar">
            <div class="d-flex">
              <img src="assets/images/mazayaPlus/cart.svg" alt="cart-img">
              <div class="cart-text-sum">Cart Summary</div>
            </div>
            <div class="payment-text">
              <div>Amount</div>
              <div>{{cartItemV2Data[0].membershipTypes[0].currency}} {{roundAmount(totalAmount)}}</div>
            </div>
            <div class="payment-text">
              <div>Vat</div>
              <div>{{cartItemV2Data[0].membershipTypes[0].currency}} {{roundAmount(totalVat)}}</div>
            </div>
            <div class="divider-checkout"></div>

            <div class="payment-text">
              <div>Grand Total</div>
              <div>{{cartItemV2Data[0].membershipTypes[0].currency}} {{roundAmount(totalAmount+totalVat)}}</div>
            </div>

          </div>



        </div>
      </div>


      <div *ngIf="cartType=='power-cart'" class="payment-card-section">
        <div *ngIf="cartPower.items.length > 0;">
          <div class="sidebar-checkout">
            <div class="payment-text-mem">Cart Details</div>
            <div class="sidebar-card" *ngFor="let item of cartPower.items; let i = index; trackBy: trackByFn">
              <div style="display: flex; gap: 10px;">
                <div style="flex: 0;">
                  <img class="side-user" src="assets/images/mazayaPlus/single-person.svg" alt="user-sidebar">
                </div>
                <div style="flex: 1;">
                  <div class="price-validity">{{item. subscriptionDuration}}</div>
                  <div class="check-header">
                    <div class="title">{{ item.cardName }}</div>
                    <div class="price-sidebar">
                      {{ item.currency }}<sub class="suber-text">{{ roundAmount(item.amount) }}</sub>
                    </div>
                  </div>
                  <div class="para-sidebar" style="font-size: 12px;">{{ item.description }}</div>

                  <div class="spinner-section-second">
                    <!-- <div class="delete-section" (click)="deleteCartItem(i)">
                      <img class="check-delete" src="assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Cart Summary -->
          <div class="payment-sidebar">
            <div class="d-flex">
              <img src="assets/images/mazayaPlus/cart.svg" alt="cart-img">
              <div class="cart-text-sum">Cart Summary</div>
            </div>
            <div class="payment-text">
              <div>Amount</div>
              <div>{{ cartPower.items[0]?.currency }} {{ roundAmount(cartPower.totalAmount) }}</div>
            </div>
            <div class="payment-text">
              <div>Vat</div>
              <div>{{ cartPower.items[0]?.currency }} {{ roundAmount(cartPower.totalVat) }}</div>
            </div>
            <div class="divider-checkout"></div>
            <div class="payment-text">
              <div>Grand Total</div>
              <div>{{ cartPower.items[0]?.currency }} {{ roundAmount(cartPower.grandTotal) }}</div>
            </div>
          </div>
        </div>


      </div>

    </div>
    <div class="column">

      <div class="payment-card-section" *ngIf="!isUpgrade && !isOldToNew">
        <div class="payment-text-mem">Membership Details</div>



        <form *ngIf="cartType!='power-cart'" [formGroup]=" myForm" (ngSubmit)="onSubmit()">
          <div formArrayName="items">
            <div *ngFor="let item of itemControls.controls; let i = index" [formGroupName]="i">
              <fieldset [style.display]="i === 0 ? 'block' : 'none'">
                <legend *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Adult'">Member(Adult)</legend>
                <legend *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Child'">Member(Child)</legend>
                <div class="box">
                  <mat-form-field class="textbox">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="firstName" (input)="onInputTrim('firstName')"
                      [readonly]="userInfo?.mazayaCardCount === 0 && i === 0" />
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('firstName').hasError('required')">
                      First name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="box">
                  <mat-form-field class="textbox">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="lastName" (input)="onInputTrim('lastName')"
                      [readonly]="userInfo?.mazayaCardCount === 0 && i === 0" />
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('lastName').hasError('required')">
                      Last name is required
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="box">
                  <mat-form-field class="textbox">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email"
                      [readonly]="userInfo?.mazayaCardCount === 0 && i === 0 && userEmail!==''" />
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('email').hasError('required')">
                      Email is required.
                    </mat-error>
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('email').hasError('email')">
                      Enter valid email.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="textbox-country read-only">
                        <mat-label>code</mat-label>
                        <input matInput value="+971" />
                      </mat-form-field>
                    </div>

                  </div>
                  <div class="column-details">

                    <div class="box">
                      <mat-form-field class="textbox-phone ">
                        <mat-label>Phone Number</mat-label>
                        <input [readonly]="userInfo?.mazayaCardCount === 0 && i === 0" matInput
                          formControlName="phoneNumber" oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                          maxlength="9" />
                        <mat-error
                          *ngIf="myForm.get('items.0').get('phoneNumber').hasError('required') || myForm.get('items.0').get('phoneNumber').hasError('minlength')">
                          Enter a valid phone number
                        </mat-error>

                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="calender">
                        <mat-label>Date of birth</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dob" [max]="maxDate" [readonly]="true"
                          *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Adult'" (click)="picker.open()">
                        <input matInput [matDatepicker]="picker" formControlName="dob" *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Child'"
                          [readonly]="true" [min]="maxDate" [max]="today" (click)="picker.open()">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('dob').hasError('required')">
                          Date of birth is required.
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>
                  <div class="column-details">
                    <div class="box">
                      <mat-form-field class="calender read-only">
                        <mat-label>Relation</mat-label>
                        <mat-select formControlName="relation" [disabled]="userInfo?.mazayaCardCount === 0 && i === 0">
                          <mat-option *ngFor="let relation of relations" [value]="relation.value">
                            {{relation.viewValue}}
                          </mat-option>
                        </mat-select>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('relation').hasError('required')">
                          Relation is required.
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>
                </div>

                <div class="box">
                  <div [ngClass]="{'read-only':(userInfo?.mazayaCardCount === 0 && i === 0)}" class="gender ">
                    <div>Gender</div>
                    <mat-radio-group formControlName="gender">
                      <mat-radio-button value="Male">Male</mat-radio-button>
                      <mat-radio-button value="Female">Female</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="error-validate"
                    *ngIf="myForm.get('items.0').get('gender').hasError('required') && submitted">
                    Gender is required.
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <button class="price-btn" submit>Confirm </button>
        </form>
        <form *ngIf="cartType=='power-cart'" [formGroup]="myForm" (ngSubmit)="onSubmitPower()">
          <div formArrayName="items">
            <div *ngFor="let item of itemControls.controls; let i = index" [formGroupName]="i">
              <fieldset [style.display]="i === 0 ? 'block' : 'none'">
                <legend>Member</legend>
                <div class="box">
                  <mat-form-field class="textbox read-only">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="firstName" (input)="onInputTrim('firstName')" />

                  </mat-form-field>
                </div>
                <div class="box">
                  <mat-form-field class="textbox read-only">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="lastName" (input)="onInputTrim('lastName')" />

                  </mat-form-field>
                </div>

                <div class="box">
                  <mat-form-field class="textbox read-only">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" />

                  </mat-form-field>
                </div>


                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="textbox-country read-only">
                        <mat-label>code</mat-label>
                        <input matInput value="+971" />

                      </mat-form-field>

                    </div>
                  </div>
                  <div class="column-details">
                    <div class="box">
                      <mat-form-field class="textbox-phone read-only">
                        <mat-label>Phone Number</mat-label>
                        <input matInput formControlName="phoneNumber" />

                      </mat-form-field>

                    </div>
                  </div>
                </div>
                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="calender">
                        <mat-label>Date of birth</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dob" [max]="maxDate" [readonly]="true"
                          *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Adult'" (click)="picker.open()">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('dob').hasError('required')">
                          Date of birth is required.
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>
                  <div class="column-details">
                    <div class="box">
                      <mat-form-field class="calender read-only" appearance="fill">
                        <mat-label>Relation</mat-label>
                        <mat-select formControlName="relation" [disabled]="true">
                          <mat-option value="Myself">My Self
                          </mat-option>
                        </mat-select>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('relation').hasError('required')">
                          Relation is required.
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>
                </div>

                <div class="box">
                  <div class="gender">
                    <div>Gender</div>
                    <mat-radio-group formControlName="gender">
                      <mat-radio-button value="Male">Male</mat-radio-button>
                      <mat-radio-button value="Female">Female</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="error-validate"
                    *ngIf="myForm.get('items.0').get('gender').hasError('required') && submitted">
                    Gender is required.
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <button class="price-btn" submit>Confirm </button>
        </form>

      </div>

      <div class="payment-card-section" *ngIf="isOldToNew && !isUpgrade">
        <div class="payment-text-mem">Membership Details</div>

        <div class="payment-text-instruction" *ngIf="itemControls.controls.length>1">Swipe right to add/view other family members
        </div>

        <form *ngIf="cartType!='power-cart'" [formGroup]=" myForm" (ngSubmit)="onSubmit()">
          <div formArrayName="items">
            <div *ngFor="let item of itemControls.controls; let i = index" [formGroupName]="i">
              <fieldset [style.display]="i === currentMember-1 ? 'block' : 'none'">
                <legend *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Adult'">Member(Adult)</legend>
                <legend *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Child'">Member(Child)</legend>
                <legend *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Nanny'">Member(Nanny)</legend>
                <mat-radio-group formControlName="selection" *ngIf="i!=0 && filteredMembers?.length>0"
                  (change)="onMemberTypeChange($event)">
                  <mat-radio-button value="new">New Member</mat-radio-button>
                  <mat-radio-button value="existing">Existing Member</mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="textbox" *ngIf="myForm.get('items')?.controls[i].get('selection').value!='new'">
                  <mat-label>Choose family member</mat-label>
                  <mat-select (selectionChange)="onChoosenMember($event)">
                    <mat-option *ngFor="let members of filteredMembers let j = index" [value]="members.id">
                      {{members.firstname}} {{members.lastname}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="box">
                  <mat-form-field class="textbox">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="firstName" (input)="onInputTrim('firstName')"
                      [readonly]="(i === 0 && userInfo?.mazayaCardCount==0) || ( i === 0 && userInfo?.mazayauserdetails.isPurchasedMazayaPlusOld)" />
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('firstName').hasError('required')">
                      First name is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="box">
                  <mat-form-field class="textbox">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="lastName" (input)="onInputTrim('lastName')"
                      [readonly]="(i === 0 && userInfo?.mazayaCardCount==0) || ( i === 0 && userInfo?.mazayauserdetails.isPurchasedMazayaPlusOld)" />
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('lastName').hasError('required')">
                      Last name is required
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="box">
                  <mat-form-field class="textbox">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" [readonly]="(i === 0 && userInfo?.mazayaCardCount==0) || ( i === 0 && userInfo?.mazayauserdetails.isPurchasedMazayaPlusOld)" />
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('email').hasError('required')  && i==0 ">
                      Email is required.
                    </mat-error>
                    <mat-error class="mat-mdc-form-field-error-wrapper"
                      *ngIf="myForm.get('items.0').get('email').hasError('email')  && i==0">
                      Enter valid email.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="textbox-country read-only">
                        <mat-label>code</mat-label>
                        <input matInput value="+971" />
                      </mat-form-field>
                    </div>

                  </div>
                  <div class="column-details">

                    <div class="box">
                      <mat-form-field class="textbox-phone ">
                        <mat-label>Phone Number</mat-label>
                        <input [readonly]="(i === 0 && userInfo?.mazayaCardCount==0) || ( i === 0 && userInfo?.mazayauserdetails.isPurchasedMazayaPlusOld)" matInput
                          formControlName="phoneNumber" oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                          maxlength="9" />
                        <mat-error
                          *ngIf=" (i==0) && myForm.get('items.0').get('phoneNumber').hasError('required') || myForm.get('items.0').get('phoneNumber').hasError('minlength')">
                          Enter a valid phone number
                        </mat-error>

                      </mat-form-field>
                    </div>

                  </div>
                </div>

                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="calender">
                        <mat-label>Date of birth</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dob" [max]="maxDate" [readonly]="true"
                          *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Adult' || myForm.get('items')?.controls[i].get('memberType').value==='Nanny'" (click)="picker.open()">
                        <input matInput [matDatepicker]="picker" formControlName="dob" *ngIf="myForm.get('items')?.controls[i].get('memberType').value==='Child'"
                          [readonly]="true" [min]="maxDate" [max]="today" (click)="picker.open()">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('dob').hasError('required') && i==0">
                          Date of birth is required.
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>
                  <div class="column-details">
                    <div class="box">
                      <mat-form-field class="calender" [ngClass]="{'read-only': i== 0 && myForm.get('items.0').get('relation').value==='Myself'}">
                        <mat-label>Relation</mat-label>
                        <mat-select formControlName="relation" [disabled]="">
                          <mat-option *ngFor="let relation of relations" [value]="relation.value" [disabled]="relation.value === 'Myself'">
                            {{relation.viewValue}}
                          </mat-option>
                        </mat-select>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('relation').hasError('required')  && i==0">
                          Relation is required.
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>
                </div>

                <div class="box">
                  <div [ngClass]="{'read-only':((i === 0 && userInfo?.mazayaCardCount==0) || ( i === 0 && userInfo?.mazayauserdetails.isPurchasedMazayaPlusOld))}" class="gender ">
                    <div>Gender</div>
                    <mat-radio-group formControlName="gender">
                      <mat-radio-button value="Male">Male</mat-radio-button>
                      <mat-radio-button value="Female">Female</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="error-validate"
                    *ngIf="myForm.get('items.0').get('gender').hasError('required') && i==0 && submitted">
                    Gender is required.
                  </div>
                </div>
                <div class="number-container">
                  <span class="arrow" (click)="handleLeftArrow()">
                    <img class="arrow-card" src="../../../../assets/images/mazayaPlus/arrow-left.svg" alt="" srcset="">
                  </span>
                  <span class="number">
                    {{currentMember}} / {{itemControls.controls.length}}
                  </span>
                  <span class="arrow" (click)="handleRightArrow()">
                    <img class="arrow-card" src="../../../../assets/images/mazayaPlus/arrow-right.svg" alt="" srcset="">
                  </span>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="confirmCheck" formControlName="agreeConditions">
            <label class="form-check-label" for="confirmCheck">
              I agree with Terms & Conditions of the purchase.
            </label>
          </div>
          <div class="error-validate" *ngIf="myForm.get('agreeConditions')?.invalid && submitted">
            Please confirm to proceed
          </div>
          <!-- confirm button -->
          <button class="price-btn" submit>Confirm </button>
        </form>
        <form *ngIf="cartType=='power-cart'" [formGroup]="myForm" (ngSubmit)="onSubmitPower()">
          <div formArrayName="items">
            <div *ngFor="let item of itemControls.controls; let i = index" [formGroupName]="i">
              <fieldset [style.display]="i === 0 ? 'block' : 'none'">
                <legend>Member</legend>
                <div class="box">
                  <mat-form-field class="textbox read-only">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="firstName" (input)="onInputTrim('firstName')" />

                  </mat-form-field>
                </div>
                <div class="box">
                  <mat-form-field class="textbox read-only">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="lastName" (input)="onInputTrim('lastName')" />

                  </mat-form-field>
                </div>
                <div class="box">
                  <mat-form-field class="textbox read-only">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" />
                  </mat-form-field>
                </div>
                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="textbox-country read-only">
                        <mat-label>code</mat-label>
                        <input matInput value="+971" />

                      </mat-form-field>

                    </div>
                  </div>
                  <div class="column-details">
                    <div class="box">
                      <mat-form-field class="textbox-phone read-only">
                        <mat-label>Phone Number</mat-label>
                        <input matInput formControlName="phoneNumber" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row-details">
                  <div class="column-details mr-16">
                    <div class="box">
                      <mat-form-field class="calender">
                        <mat-label>Date of birth</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="dob" [max]="maxDate" [readonly]="true"
                           (click)="picker.open()">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('dob').hasError('required')">
                          Date of birth is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="column-details">
                    <div class="box">
                      <mat-form-field class="calender read-only" appearance="fill">
                        <mat-label>Relation</mat-label>
                        <mat-select formControlName="relation" [disabled]="true">
                          <mat-option value="Myself">My Self
                          </mat-option>
                        </mat-select>
                        <mat-error class="mat-mdc-form-field-error-wrapper"
                          *ngIf="myForm.get('items.0').get('relation').hasError('required')">
                          Relation is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="gender">
                    <div>Gender</div>
                    <mat-radio-group formControlName="gender">
                      <mat-radio-button value="Male">Male</mat-radio-button>
                      <mat-radio-button value="Female">Female</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="error-validate"
                    *ngIf="myForm.get('items.0').get('gender').hasError('required') && submitted">
                    Gender is required.
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="confirmCheck" formControlName="agreeConditions">
            <label class="form-check-label" for="confirmCheck">
              I agree with Terms & Conditions of the purchase.
            </label>
          </div>
          <div class="error-validate" *ngIf="myForm.get('agreeConditions')?.invalid && submitted">
            Please confirm to proceed
          </div>
          <button class="price-btn" submit>Confirm</button>
        </form>

      </div>
      <div class="payment-card-section" *ngIf="isUpgrade">
        <div class="payment-text-mem">Membership Upgrade Details</div>
        <div *ngIf="isUpgrade" class="payment-card-section">
          <div class="sidebar-checkout">
            <div class="" *ngFor="let cartItem of cartItemV2Data[0]?.cards ; let i=index; trackBy: trackByFn">
              <div class="sidebar-card" style="display: flex; gap: 10px;" *ngIf="cartItem.count!=0">
                <div style="flex: 0;">
                  <img class="side-user" src="assets/images/mazayaPlus/iconCarrier-single.svg" alt="user-sidebar">
                </div>
                <div style="flex: 1;">
                  <div class="price-validity">
                    {{cartItem.subscriptionDuration}}
                  </div>
                  <div class="check-header">

                    <div class="title">{{cartItem.cardName}}</div>
                    <!-- <div class="price-sidebar">{{cartItem.currency}}<sub
                        class="suber-text">{{roundAmount(cartItem.amount)}}</sub></div> -->
                  </div>
                  <div class="description1">
                    {{cartItem.description}}
                  </div>
                </div>
              </div>
            </div>
            <div class="payment-sidebar">
              <div class="payment-text">
                <div>Amount</div>
                <div>{{upgradeAmountTotal}}</div>
              </div>
              <div class="payment-text">
                <div>Vat</div>
                <div>{{upgradeVatTotal}}</div>
              </div>
              <div class="divider-checkout"></div>
              <div class="payment-text">
                <div>Grand Total</div>
                <div>{{roundAmount(upgradeAmountTotal+upgradeVatTotal)}}</div>
              </div>
            </div>
          </div>
          <button class="price-btn" *ngIf="!userInfo.isGoldmembership" (click)="upgradeConfirm()">Confirm </button>
          <button class="price-btn" *ngIf="userInfo.isGoldmembership" (click)="upgradeGoldAddOnConfirm()">Confirm
          </button>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="payment-card-section">
        <div class="payment-text-mem">Choose method</div>
        <div class="payment-method" [class.selected]="selectedPaymentMethod === 'Credit/Debit Card'"
          (click)="selectPaymentMethod('Credit/Debit Card')">
          <div class="credit-text">Credit/Debit Card</div>
          <img src="assets/images/mazayaPlus/payment.svg" alt="payment">
        </div>
        <div class="payment-method" [class.selected]="selectedPaymentMethod === 'Tabby'"
          (click)="selectPaymentMethod('Tabby')">
          <div class="credit-text">Tabby</div>
          <img style="height: 22px; width: 33px;" src="assets/images/mazayaPlus/tabby_badge.png" alt="payment">
        </div>
        <!-- <div class="payment-method" [class.selected]="selectedPaymentMethod === 'ApplePay'" (click)="selectPaymentMethod('ApplePay')">
          <div class="credit-text">Apple Pay</div>
          <img style="height: 22px; width: 33px;" src="assets/images/mazayaPlus/apple-pay.svg" alt="payment">
          <apple-pay-button 
          #applePayButton
          buttonstyle="black"
          type="plain"
          locale="en-US"
          [disabled]="selectedPaymentMethod !== 'ApplePay'">
        </apple-pay-button>
        
        </div> -->
        <apple-pay-button
          
        buttonstyle="black"
        type="plain"
        locale="en-US"
        (click)="onApplePayButtonClicked($event)">
      </apple-pay-button>
      </div>
      <div *ngIf="showError" class="error-message">
        Please select a payment method before proceeding.
      </div>
    </div>
  </div>
  <!-- -->


  <div #formContainer></div>
</div>

</html>